var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.AssetControlPanel,{attrs:{"asset":_setup.asset,"show-model":"","is-child":_vm.isChild}},[_c(_setup.AssetConfigForm,{attrs:{"asset":_setup.asset,"properties":[
      'iu_set_temp_status',
      'iu_airflow_status',
      'iu_vert_air_direction_status',
      'iu_horiz_air_direction_status',
      'iu_mode_status',
      'iu_on_off_status'
    ]}},[_c(_setup.ControlRow,[_c(_setup.PropertyValue,{class:_setup.nudgeDownExceptOnMobileClass,attrs:{"device":_setup.asset,"property":"iu_indoor_temp_status"}}),_c(_setup.ControlColumn,{class:_setup.fullWidthOnMobileClass},[_c(_setup.FormField,{attrs:{"name":"iu_set_temp_status","hide-details":""}}),_c(_setup.FormField,{attrs:{"name":"iu_airflow_status","hide-details":""}})],1),_c(_setup.ControlColumn,{class:_setup.fullWidthOnMobileClass},[_c(_setup.FormField,{attrs:{"name":"iu_vert_air_direction_status","hide-details":""}}),(_setup.horizAirDirectionStatus.value != 0)?_c(_setup.FormField,{attrs:{"name":"iu_horiz_air_direction_status","hide-details":""}}):_vm._e()],1),_c(_setup.ControlColumn,{class:_setup.fullWidthOnMobileClass},[_c(_setup.FormField,{attrs:{"name":"iu_mode_status","hide-details":""}}),_c(_setup.FormField,{attrs:{"name":"iu_on_off_status","single-line":"","hide-details":""}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }